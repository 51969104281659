import { ReactNode, useRef } from "react";
import TermsAndPolicesNavigation from "./TermsAndPolicesNavigation";
import TermsAndPolicesHeader from "./TermsAndPolicesHeader";
import LastUpdatedInfo from "./LastUpdatedInfo";
import { ArrowUpward } from "@mui/icons-material";

export default function TermsAndPolicesLayout({
  type,
  headerTitle,
  children,
}: {
  type?: "service" | "privacy" | "disclaimer";
  headerTitle: string;
  children: ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div
      ref={ref}
      className="flex flex-col overflow-y-auto w-full h-full bg-white"
    >
      {/* Nav */}
      <TermsAndPolicesNavigation type={type} />

      <div className="px-6 pt-10 pb-[55px]">
        {/* Header */}
        <TermsAndPolicesHeader title={headerTitle} />
        {/* Content */}

        <div className="flex pt-2 grid grid-cols-1 space-y-10 md:space-y-0 md:grid-cols-3">
          <LastUpdatedInfo />

          <div>
            <div className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
              {children}
            </div>
            <button
              className="mt-20 flex items-center gap-x-2"
              onClick={scrollToTop}
            >
              <p className="font-['Inter'] font-normal text-base text-[#525252] leading-[22px]">
                Back to top
              </p>
              <ArrowUpward className="!w-4 !h-4 !text-[#525252] " />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
