import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import TradeGPT from "./components/TradeGPT";
import { v4 as uuidv4 } from "uuid";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Faq from "./components/Faq";
import SharedChat from "./components/Share/SharedChat";
import ThankYou from "./components/ThankYou";
import PlansWrapper from "./components/Plans/PlansWrapper";
import TermsAndPolices from "./components/terms_and_polices/TermsAndPolices";
import TermsOfService from "./components/terms_and_polices/TermsOfService";
import PrivacyPolicy from "./components/terms_and_polices/PrivacyPolicy";
import LegalDisclaimer from "./components/terms_and_polices/LegalDisclaimer";
import NotFoundPage from "./components/404page";
import Session from "./components/Session/Session";
import { AppDispatch } from "./redux/store";
import { sendMessage } from "./redux/slices/websocketSlice";
import LandingPage from "./components/LandingPage";
import SignIn from "./components/SignInP";
import SignUp from "./components/SignUpP";
import AppWrapper from "./components/AppWrapper";
import DeletedAccountPage from "./components/DeletedAccountPage";
import LocationListener from "./components/LocationListener";
import { injectCjPageVisit } from "./components/Session/helpers";

const AppRoutes = () => {
  const dispatch: AppDispatch = useDispatch();
  const savedDarkModeSetting = localStorage.getItem("tradegpt-darkmode");
  const user = useSelector((state: { auth }) => state?.auth?.currentUser);
  const authLoading = useSelector((state: { auth }) => state.auth.loading);
  const isUserSignedIn = useMemo(() => {
    return (!!user && !authLoading);
  }, [user, authLoading]);
  // const websocket = useSelector((state: any) => state.websocket);
  const user_id = user?.userId || "";
  useEffect(() => {
    if (user_id) {
      dispatch(sendMessage([`ACTIVE:${user_id}`]));
    }
  }, [user_id]);

  useEffect(() => {
    const darkModePreference
      = savedDarkModeSetting !== null ? savedDarkModeSetting === "true" : true; // Default to true if no setting is saved
    document.body.className = darkModePreference ? "dark" : "light";
  }, [dispatch, savedDarkModeSetting]);

  const handleLocationChange = useCallback((location) => {
    const email = user?.email || "";
    const pathname = location.pathname;
    // Add your custom logic here, such as dispatching actions or logging
    if (pathname === "/") {
      injectCjPageVisit("homepage", "N/A", email);
    }
    else if (pathname.includes("chat")) {
      injectCjPageVisit("applicationStart", user_id, email);
    }
    else if (pathname.includes("plans")) {
      injectCjPageVisit("productDetail", user_id, email);
    }
    else if (pathname.includes("signup") || pathname.includes("signin")) {
      injectCjPageVisit("accountSignup", "N/A", email);
    }
  }, []);

  return (
    <Router>
      <LocationListener onLocationChange={handleLocationChange} />
      <AppWrapper>
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          {/* <Route path="/" element={<LandingPage />} /> */}
          <Route
            path="/"
            element={
              authLoading ? <></> : (isUserSignedIn ? <Navigate to={`/chat/${uuidv4()}`} /> : <LandingPage />)
            }
          />
          <Route path="/dashboard" element={<TradeGPT showDashboard />} />
          <Route path="/plans" element={<PlansWrapper />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/chat" element={<Navigate to={`/chat/${uuidv4()}`} />} />
          <Route path="/chat/:session_id" element={<TradeGPT key={uuidv4()} />} />
          <Route path="/share/:session_id/:title?" element={<SharedChat />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/policies" element={<TermsAndPolices />} />
          <Route path="/policies/service-terms" element={<TermsOfService />} />
          <Route path="/policies/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/session" element={<Session />} />
          <Route path="/prompt/:prompt" element={<TradeGPT />} />
          <Route path="/account-deleted" element={<DeletedAccountPage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route
            path="/policies/legal-disclaimer"
            element={<LegalDisclaimer />}
          />
        </Routes>
      </AppWrapper>
    </Router>
  );
};
// </>

export default AppRoutes;
