// src/index.tsx
import React from "react";
import "./darkmode.scss";
import store from "./redux/store";
import { Provider } from "react-redux";
import "github-markdown-css";
import "firebase/firestore";
import { firebaseApp } from "./components/auth/firebase";
import AuthListener from "./components/auth/AuthListener";
import { createRoot } from "react-dom/client";
import AppRoutes from "./Routes";
import ErrorBoundary from "./components/Errorpage";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import "./index.css";
// init firebase
// eslint-disable-next-line
const fb = firebaseApp
// react v18
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <Provider store={store}>
      <AuthListener />
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>,
  // </React.StrictMode>
);
