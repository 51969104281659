import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LocationListener = ({ onLocationChange }) => {
  const location = useLocation();

  useEffect(() => {
    onLocationChange(location);
  }, [location, onLocationChange]);

  return null;
};

export default LocationListener;
