import useEnhancedNavigate from "../Session/helpers";
import TermsAndPolicesHeader from "./components/TermsAndPolicesHeader";
import TermsAndPolicesNavigation from "./components/TermsAndPolicesNavigation";

interface TermsAndPolicesOptionItem {
  title: string;
  description: string;
  url: string;
}

const legalActions: TermsAndPolicesOptionItem[] = [
  {
    title: "Terms of Service",
    description:
      "These terms of service govern your use of our cutting-edge chatbot, TradeGPT, providing you with a unique and dynamic conversational experience",
    url: "/policies/service-terms",
  },
  {
    title: "Privacy Policy",
    description:
      "This document serves as a legally binding agreement between you, the user, and TA Fintech Inc",
    url: "/policies/privacy-policy",
  },
  {
    title: "Legal Disclaimer",
    description: `This legal disclaimer governs the utilization of TradeGPT by TA Fintech Inc., Chatbot ("Chatbot")`,
    url: "/policies/legal-disclaimer",
  },
];

export default function TermsAndPolices() {
  const navigate = useEnhancedNavigate();

  const onOptionClicked = (optionUrl: string) => {
    navigate(optionUrl);
  };

  return (
    <div className="flex flex-col overflow-y-auto w-full h-full bg-white">
      {/* Nav */}
      <TermsAndPolicesNavigation />

      <div className="px-6 pt-10 pb-[55px] space-y-10">
        {/* Header */}
        <TermsAndPolicesHeader title="Terms & Policies" />
        {/* Content */}

        <div className="space-y-10">
          <h1 className="text-[48px] text-[#525252] font-['Inter'] font-normal leading-[58px]">
            Legal
          </h1>

          <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6">
            {legalActions.map(x => (
              <div
                key={x.title}
                className="flex flex-col h-[410px] w-full cursor-pointer md:w-[328px] border border-colorsseparatordark p-6"
                onClick={() => onOptionClicked(x.url)}
              >
                <div className="flex-1">
                  <p className="text-[28px] text-[#1C1C1E] font-['Inter'] font-bold">
                    {x.title}
                  </p>
                </div>
                <p className="text-lg text-[#1C1C1E] font-['Inter'] font-normal">
                  {x.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
