import { useState, useEffect, useMemo, useRef } from "react";
import {
  Button,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import "./tradegpt.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  finchatMessageCancel,
  finchatMessageLoad,
} from "../redux/slices/finchatSlice";
import {
  FetchMessagesFunction,
  Message,
  RootState,
  sendMessageFunction,
} from "../assets/interfaces/interfaces";
import ChatWindow from "./ChatWindow";
import MessageInput from "./MessageInput";
import Sidebar from "./Sidebar/Sidebar";
import MenuIcon from "@mui/icons-material/Menu";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import TradeLogo from "../assets/images/finchatGPT/TradeLogo-D.png";
import TradeDark from "../assets/images/finchatGPT/taLogo.png";
import ShareIcon from "@mui/icons-material/IosShare";
import { transformArray } from "../helpers";
import LimitModal from "./LimitModal";
import classNames from "classnames";
import { getAuth } from "firebase/auth";
import { AppDispatch } from "../redux/store";
import { fetchPrompts } from "../redux/slices/promptsSlice";
import useEnhancedNavigate from "./Session/helpers";
import Dashboard from "./Dashboard/Dashboard";
import { addMessageOptimistically } from "../redux/slices/pastChatsSlice";
import { fetchAllPlan } from "../redux/slices/allPlanSlice";
// Main App component
const FinchatGPT = ({ showDashboard = false }) => {
  const sidebarCarrotRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: { auth }) => state.auth.currentUser);
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);
  const plan = useSelector((state: { plan }) => state?.plan?.plan);
  const tokenStatus = useSelector((state: { token }) => state.token.status);
  const { session_id, prompt } = useParams();
  const sessionIdSafe = session_id || uuidv4(); // Default to a new UUID if sessionId is undefined
  const finchatMessage = useSelector(
    (state: RootState) => state?.finchat?.finchatMessages[session_id],
  );
  const loading = useSelector(
    (state: RootState) => state?.finchat?.loading,
  );
  const { status: allStatus } = useSelector((state: { plans }) => state.plans);
  const { status: planStatus } = useSelector((state: { plan }) => state.plan);
  const { pastMessages } = useSelector((state: RootState) => state.session);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useEnhancedNavigate();

  const auth = getAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const prompts = useSelector((state: RootState) => state?.prompts.prompts);
  const promptsStatus = useSelector(
    (state: RootState) => state?.prompts.status,
  );
  const { status: userValidationStatus, isValid } = useSelector(

    (state: { validUser }) => state?.validUser,
  );

  const accessToken = user?.accessToken || "";
  const user_id = user?.userId || "";

  const [messages, setMessages] = useState<Record<string, Partial<Message>[]>>({});
  const [invalidChat, setInvalidChat] = useState<boolean>(false);
  const [shareModal, setShareModal] = useState<boolean>(false);

  const isUserSignedIn = useMemo(() => {
    return !!auth?.currentUser;
  }, [auth?.currentUser]);

  const openShareModal = () => {
    setShareModal(true);
  };

  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const [flow, setFlow] = useState(0);

  const makeUserMessage = (text: string) => {
    return {
      isBot: false,
      output: text,
    };
  };

  const fetchMessages: FetchMessagesFunction = (text, input_type, token) => {
    if (text.trim()) {
      const chat_id = uuidv4();
      dispatch(
        finchatMessageLoad({
          input: text,
          chat_id,
          session_id: sessionIdSafe,
          user_id,
          accessToken: token ? token : accessToken,
          input_type,
        }),
      );

      setMessages((allMessages) => {
        const prevMessages = allMessages[sessionIdSafe] || [];

        return {
          ...allMessages,
          [sessionIdSafe]: [
            ...prevMessages,
            makeUserMessage(text),
          ],
        };
      });
    }
  };

  const setTempChatOnSidebar = (title, sessionId) => {
    if (!sessionId) return;
    dispatch(addMessageOptimistically({
      date: new Date().toISOString(),
      title,
      sessionId,
    }));
  };

  const handleSendMessage: sendMessageFunction = (text, input_type) => {
    if (plan.promptsLeft === 0) {
      setIsLimitModalOpen(true);
      return;
    }
    // const decodedToken = jwtDecode(accessToken) as any;
    // const currentTime = Date.now() / 1000;
    // const auth = getAuth();
    // if (decodedToken.exp < currentTime) {
    //   getIdToken(auth.currentUser as any, true).then((token) => {
    //     dispatch(
    //       setUser({
    //         ...user,
    //         accessToken: token,
    //       })
    //     );
    //     fetchMessages(text, input_type, token);
    //   });
    // } else {
    fetchMessages(text, input_type);
    setTempChatOnSidebar(text, sessionIdSafe);
    // }
  };

  const handlePromptSelect = (prompt: string) => {
    if (!loading && isUserSignedIn) {
      handleSendMessage(prompt, "recommend");
      return;
    }
    setSignInModalOpen(true);
  };

  const handleCancel = () => {
    dispatch(finchatMessageCancel(session_id));
  };

  const handleNewChat = () => {
    handleCancel();

    const newSessionId = uuidv4();
    navigate(`/chat/${newSessionId}`);
    setTimeout(() => {
      setMessages((allMessages) => {
        return {
          ...allMessages,
          [newSessionId]: [],
        };
      });
    }, 300);
  };

  // Toggle drawer function
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (userValidationStatus === "succeeded" && !isValid) {
      navigate("/account-deleted");
    }
  }, [userValidationStatus, isValid, navigate]);

  useEffect(() => {
    if (pastMessages.length && !!user) {
      const formattedPastChat = transformArray(
        pastMessages,
        user,
        setInvalidChat,
      );
      setMessages((allMessages) => {
        return {
          ...allMessages,
          [sessionIdSafe]: formattedPastChat,
        };
      });
    }
  }, [pastMessages, user]);

  useEffect(() => {
    if (!prompts.length && promptsStatus === "idle" && tokenStatus === "succeeded") {
      dispatch(fetchPrompts());
    }
  }, [dispatch, prompts, promptsStatus, tokenStatus]);

  useEffect(() => {
    if (tokenStatus === "succeeded") {
      dispatch(fetchAllPlan());
    }
  }, [tokenStatus]);

  useEffect(() => {
    if (invalidChat) {
      // Not show error for now cause is showing it when user has changed.

      // dispatch(
      //   showNotification({
      //     message: `Unable to load conversation ${sessionIdSafe}`,
      //     severity: "error",
      //     horizontal: "right",
      //   })
      // );
      setInvalidChat(false);
      navigate(`/chat/${uuidv4()}`);
    }
  }, [invalidChat, dispatch, sessionIdSafe, navigate]);

  useEffect(() => {
    const handleMouseEnter = () => {
      const drawer = document.querySelector(".MuiDrawer-paperAnchorDockedLeft");
      if (drawer) {
        // @ts-ignore
        drawer.style.opacity = "0.6";
      }
    };

    const handleMouseLeave = () => {
      const drawer = document.querySelector(".MuiDrawer-paperAnchorDockedLeft");
      if (drawer) {
        // @ts-ignore
        drawer.style.opacity = "";
      }
    };

    const sidebarCarrot = sidebarCarrotRef.current;
    if (sidebarCarrot) {
      sidebarCarrot.addEventListener("mouseenter", handleMouseEnter);
      sidebarCarrot.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (sidebarCarrot) {
        sidebarCarrot.removeEventListener("mouseenter", handleMouseEnter);
        sidebarCarrot.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    if (finchatMessage?.output?.length && finchatMessage?.chat_id) {
      setMessages((allMessages) => {
        const prevMessages = allMessages[sessionIdSafe] || [];
        const existingMessageIndex = prevMessages.findIndex(
          m => m.chat_id === finchatMessage.chat_id,
        );
        // Update the existing bot response
        const sliceIndex = existingMessageIndex >= 0 ? existingMessageIndex : prevMessages.length;
        const messageBefore = prevMessages.at(sliceIndex - 1);

        return {
          ...allMessages,
          [sessionIdSafe]: [
            ...prevMessages.slice(0, sliceIndex),
            ...(
              messageBefore?.isBot || finchatMessage.input !== messageBefore?.output
                ? [makeUserMessage(finchatMessage.input)]
                : []
            ),
            {
              ...prevMessages[sliceIndex],
              outputLoading: finchatMessage.outputLoading,
              output: finchatMessage.output,
              isBot: true,
              chart_data: finchatMessage.chart_data, // Update with new chart data
              chart_flag: finchatMessage.chart_flag, // Update the chart flag
              chat_id: finchatMessage.chat_id,
            },
            ...prevMessages.slice(sliceIndex + 1),
          ],
        };
      });
    }
  }, [finchatMessage]);

  useEffect(() => {
    if (
      prompt
      && isUserSignedIn
      && planStatus === "succeeded"
      && allStatus === "succeeded"
    ) {
      // Check if there is a prompt and the user is signed in
      navigate(`/chat/${uuidv4()}`);
      handleSendMessage(decodeURIComponent(prompt), "user_type"); // Start a new chat session with the prompt, ensure to decode URI component
    }
  }, [prompt, isUserSignedIn, allStatus, planStatus, navigate]);

  return (
    <div className="relative z-0 flex w-full overflow-hidden h-dvh">
      <Sidebar
        signInModalOpen={signInModalOpen}
        setSignInModalOpen={setSignInModalOpen}
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        isTablet={isTablet}
        isDarkMode={isDarkMode}
        handleNewChat={handleNewChat}
        isMobile={isMobile}
        flow={flow}
        setFlow={setFlow}
        messages={messages[sessionIdSafe]}
        setMessages={setMessages}
      />
      <div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
        <main className="relative h-full w-full flex-1 overflow-auto transition-width">
          <Tooltip
            title={`${drawerOpen ? "Close" : "Open"} sidebar`}
            placement="right"
          >
            <div
              ref={sidebarCarrotRef}
              onClick={() => setDrawerOpen(prev => !prev)}
              className={`${isMobile ? "hidden" : "block"
                } fixed left-0 top-1/2 z-40 sidebar-carrot  ${!drawerOpen ? "-ml-[250px] closed" : ""
                }`}
            >
              <button>
                <span>
                  <div className="flex h-[88px] w-8 items-center justify-center sidebar-carrot-inner">
                    <div className="flex h-6 w-6 flex-col items-center">
                      <div className="h-3 w-1 rounded-full bg-black translator-a"></div>
                      <div className="h-3 w-1 rounded-full bg-black translator-b"></div>
                    </div>
                  </div>
                </span>
              </button>
            </div>
          </Tooltip>

          {
            showDashboard
              ? (
                  <Dashboard />
                )
              : (
                  <>
                    <div
                      role="presentation"
                      className="flex h-full flex-col chatmessagescrollbar"
                    >
                      <div className="flex-1 overflow-hidden">
                        <div className="relative h-full">
                          <div className="w-full h-full overflow-y-auto scroll-container">
                            <div
                              className={classNames("flex flex-col pb-9 text-sm", {
                                "h-full": !messages[sessionIdSafe]?.length,
                              })}
                            >
                              {/* flex h-full flex-col pb-9 text-sm */}
                              <header
                                className={`${isMobile ? "justify-between" : "justify-start"
                              } px-6 py-1.5 md:pt-6 gap-5 sticky top-0 flex items-center z-10 font-semibold border-b border-token-border-medium md:border-transparent bg-token-surface-primary dark:bg-token-surface-secondary`}
                              >
                                {!!isMobile && (
                                  <div className="toggle-drawer-button !items-start">
                                    <IconButton
                                      onClick={toggleDrawer}
                                      className="!text-token-text-primary"
                                    >
                                      <MenuIcon />
                                    </IconButton>
                                  </div>
                                )}
                                {!isUserSignedIn && (
                                  <Button
                                    onClick={() => {
                                      setFlow(2);
                                      setSignInModalOpen(true);
                                    }}
                                    classes={{ root: `upgrade-plan-button !w-[90px]` }}
                                  >
                                    Sign Up
                                  </Button>
                                )}
                                <div className="ml-auto">
                                  {messages[sessionIdSafe]?.length
                                    ? (
                                        <button
                                          onClick={openShareModal}
                                          className="btn relative btn-neutral btn-small flex h-9 w-9 items-center justify-center whitespace-nowrap rounded-lg border border-token-border-medium focus:ring-0"
                                        >
                                          <div className="flex w-full gap-2 items-center justify-center leading-none">
                                            <ShareIcon className="!w-[18px] !h-[18px]" />
                                          </div>
                                        </button>
                                      )
                                    : (
                                        <img
                                          src={isDarkMode ? TradeDark : TradeLogo}
                                          alt="Trade Logo"
                                          className="w-7 h-7"
                                        />
                                      )}
                                </div>
                              </header>
                              <section className="relative h-full w-full flex-1 overflow-auto transition-width">
                                <div className="flex h-full flex-col">
                                  <div className="flex-1 overflow-hidde">
                                    <div className="scroll-container h-full pt-2">
                                      <ChatWindow
                                        sessionId={sessionIdSafe}
                                        isTablet={isTablet}
                                        isMobile={isMobile}
                                        messages={messages[sessionIdSafe]}
                                        setOpen={setSignInModalOpen}
                                        isUserSignedIn={isUserSignedIn}
                                        shareModal={shareModal}
                                        setShareModal={setShareModal}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                      <MessageInput
                        sessionId={sessionIdSafe}
                        handleCancel={handleCancel}
                        setOpen={setSignInModalOpen}
                        handleSendMessage={handleSendMessage}
                        setMessages={setMessages}
                        setFlow={setFlow}
                        setSignInModalOpen={setSignInModalOpen}
                        showPrompts={!messages?.[sessionIdSafe]?.length}
                        handlePromptSelect={handlePromptSelect}
                      />
                    </div>
                    <LimitModal
                      open={isLimitModalOpen}
                      setOpen={setIsLimitModalOpen}
                      onUpgrade={() => navigate("/plans")}
                      name={user?.name}
                    />
                  </>
                )
          }
        </main>
      </div>
    </div>
  );
};

export default FinchatGPT;
