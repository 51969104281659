import moment from "moment";
import { useEffect } from "react";

export const findLongestName = (user) => {
  const possibleNames = [];

  if (!!user?.firstName && !!user?.lastName) {
    possibleNames.push(`${user?.firstName} ${user?.lastName}`);
  }
  if (user?.display_name) {
    possibleNames.push(user?.display_name);
  }
  if (user?.displayName) {
    possibleNames.push(user?.displayName);
  }

  if (possibleNames.length === 0) {
    return user?.email || "";
  }

  // Find the longest name
  const longestName = possibleNames.reduce((longest, current) => {
    return current.length > longest.length ? current : longest;
  });

  return longestName;
};

export const transformArray = (array, user, setInvalidChat: ((bool?: boolean) => void) = () => { }) => {
  const newArray = [];
  const sortedArray = [...array].sort((a, b) => a.id - b.id);
  sortedArray.forEach((item) => {
    const chatUserId = item.user_id;
    const signedInUserId = user?.userId || "";
    if (chatUserId === signedInUserId) {
      // Create an object for the user query
      newArray.push({
        isBot: false,
        output: item.user_query,
        title: item.session_title,
        date: moment(item.created_at).format("LL"),
        chat_id: `prompt-${item.message_id}`,
      });

      // Create an object for the bot response
      newArray.push({
        date: moment(item.created_at).format("LL"),
        isBot: true,
        outputLoading: false,
        message_id: item.message_id,
        output: item.bot_response,
        chart_data: item?.bot_response_payload?.chart_data, // Assuming these values are default
        chat_id: item.message_id, // Assuming message_id is the chat_id
        chart_flag: JSON.parse(item.chart_output),
        title: item.session_title,
      });
    }
    else {
      // chat does not belong to this user id
      setInvalidChat(true);
    }
  });
  return newArray;
};
export const shareTransform = (array) => {
  const newArray = [];
  const sortedArray = [...array].sort((a, b) => a.id - b.id);
  sortedArray.forEach((item) => {
    // Create an object for the user query
    newArray.push({
      isBot: false,
      output: item.user_query,
      title: item.session_title,
      date: moment(item.created_at).format("LL"),
      chat_id: `prompt-${item.message_id}`,
    });

    // Create an object for the bot response
    newArray.push({
      date: moment(item.created_at).format("LL"),
      isBot: true,
      outputLoading: false,
      output: item.bot_response,
      message_id: item.message_id,
      chart_data: item?.bot_response_payload?.chart_data, // Assuming these values are default
      chat_id: item.message_id, // Assuming message_id is the chat_id
      chart_flag: JSON.parse(item.chart_output),
      title: item.session_title,
    });
  });
  return newArray;
};
type FilteredChatsType = {
  [key: string]: unknown[];
};
export const filterChats = (arr) => {
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
  const sevenDaysAgo = moment().subtract(7, "days").format("YYYY-MM-DD");
  const thirtyDaysAgo = moment().subtract(1, "month").format("YYYY-MM-DD");
  const final: FilteredChatsType = {
    "Today": [],
    "Yesterday": [],
    "Previous 7 Days": [],
    "Previous 30 Days": [],
    "Over 30 Days Ago": [],
  };
  arr.forEach((item) => {
    const date = moment(item.date).format("YYYY-MM-DD");
    if (moment(date).isSame(today)) {
      final["Today"].push(item);
    }
    else if (moment(date).isSame(yesterday)) {
      final["Yesterday"].push(item);
    }
    else if (
      moment(date).isSameOrAfter(sevenDaysAgo)
      && !moment(date).isSame(yesterday)
      && !moment(date).isSame(today)
    ) {
      final["Previous 7 Days"].push(item);
    }
    else if (
      moment(date).isSameOrAfter(thirtyDaysAgo)
      && !moment(date).isSame(yesterday)
      && !moment(date).isSame(today)
      && !moment(date).isSameOrAfter(sevenDaysAgo)
    ) {
      final["Previous 30 Days"].push(item);
    }
    else {
      final["Over 30 Days Ago"].push(item);
    }
  });
  return final;
};
export const themesTV = {
  light: {
    layout: {
      background: { color: "#FFFFFF" }, // A soft light background that's easy on the eyes
      textColor: "rgba(28, 30, 34, 0.9)", // Dark text for high contrast on light background
    },
    grid: {
      vertLines: {
        color: "#ECEFF1", // Light grey for vertical grid lines, subtle and non-distracting
        style: 2, // Dashed lines
      },
      horzLines: {
        color: "#ECEFF1", // Matching horizontal lines with vertical ones
        style: 2, // Dashed lines
      },
    },
    timeScale: {
      borderColor: "#D1D4DC", // A border color that blends well with the layout
      timeVisible: true, // Ensure time is visible
      secondsVisible: false, // Avoid clutter by hiding seconds
    },
    priceScale: {
      borderColor: "#D1D4DC", // Matching the time scale border
      autoScale: true,
    },
    crosshair: {
      mode: 1, // Show crosshair by default
      vertLine: {
        color: "#758696", // A color that stands out but isn't too bold
        style: 0, // Solid line
        width: "1px",
        labelVisible: true,
      },
      horzLine: {
        color: "#758696", // Matching vertical crosshair
        style: 0, // Solid line
        width: "1px",
        labelVisible: true,
      },
    },
  },
  dark: {
    layout: {
      background: { color: "#1E1E1E" }, // A deep dark background for night mode
      textColor: "rgba(216, 222, 233, 0.9)", // Light text for contrast
    },
    grid: {
      vertLines: {
        color: "#2B2B43", // Darker lines for a subtle grid
        style: 2, // Dashed lines for texture
      },
      horzLines: {
        color: "#2B2B43", // Consistent with vertical lines
        style: 2, // Dashed lines to match
      },
    },
    timeScale: {
      borderColor: "#262B41", // A darker border for the timescale, blending with the background
      timeVisible: true, // Ensure time is visible
      secondsVisible: false, // Keep the interface clean
    },
    priceScale: {
      borderColor: "#262B41", // Matching the time scale border for consistency
      autoScale: true,
    },
    crosshair: {
      mode: 1, // Default crosshair visibility
      vertLine: {
        color: "#88CFFA", // A bright blue for visibility against the dark background
        style: 0, // Solid line for clarity
        width: 1,
        labelVisible: true,
      },
      horzLine: {
        color: "#88CFFA", // Matching the vertical line for consistency
        style: 0, // Solid line
        width: 1,
        labelVisible: true,
      },
    },
  },
};

export function processDataForTradingView(ohlcvData: unknown[]) {
  // Convert the data for the candlestick series
  const candlestickData = ohlcvData.map(([time, open, high, low, close]) => ({
    time: time / 1000, // Convert milliseconds to seconds if necessary
    open,
    high,
    low,
    close,
  }));

  // Convert the data for the volume series
  const volumeData = ohlcvData.map(([time, , , , , volume]) => ({
    time: time / 1000, // Convert milliseconds to seconds if necessary
    value: volume,
  }));

  return { candlestickData, volumeData };
}
export const processData = (data: number[][]) => {
  const firstDataPoint = data[0][1]; // Assuming the first element is the starting point
  return data.map(point => [
    point[0], // Keep the datetime value unchanged
    ((point[1] - firstDataPoint) / firstDataPoint) * 100, // Convert to percentage change
  ]);
};

export function useDebounce(value, setter, delay) {
  useEffect(() => {
    const handler = setTimeout(() => {
      setter(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [setter, delay, value]);
}

// formatting - using abbreviations for large numbers and rounding to 2 dp to avoid long dp
export const formatNumericValue = (value: number): string => {
  if (value >= 1_000_000_000_000) {
    return `${(value / 1_000_000_000_000).toFixed(2)}T`;
  }
  else if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(2)}B`;
  }
  else if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(2)}M`;
  }
  else {
    const decimalPart = value.toString().split(".")[1];
    if (decimalPart && decimalPart.length >= 1) {
      return value.toFixed(2);
    }
  }
  return value.toString();
};
